import { createRouter as _createRouter, createMemoryHistory, createWebHistory } from 'vue-router'
import { removeTrailingSlashes } from './middlewares.js';

const baseUrl = import.meta.env.BASE_URL;
const history = import.meta.env.SSR ? createMemoryHistory(baseUrl) : createWebHistory(baseUrl);
const pages = import.meta.glob('../../pages/**/*.vue');
const routes = Object.keys(pages).map((path) => createRoute(path)); 

function replaceSpecialChars(str) {
    return str.replace(/!(?=\w)/g, ':').replace(/@(\w+)/g, `:$1?`);
}
function createRoute(filePath) {
    //(?<file>\w+(?<extension>\.\w+$))
    const path = filePath.replace(/.*\/pages|index|\.vue$/g, '');
    const component = pages[filePath];
    const routePath = removeTrailingSlashes(path);
    return {
        path: replaceSpecialChars(routePath),
        component,
        name: routePath,
    };
}

// routes.push({ path: '/:pathMatch(.*)*', name: '/404', meta: { error: true, errorCode: 404}, component: auroraConfig()?.router?.errorComponents?.[404] || (() => import('@aurora/errors/404.vue'))})

// console.log(routes);

export function createRouter() {
    return _createRouter({
        history: history,
        routes,
        scrollBehavior(to) {
            if (to.hash) {
                return { top: to.hash, behavior: 'smooth' }
            }
            return { top: 0 }
        }
    })
}
