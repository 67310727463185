<template>
    <RouterView />
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router';
import { setCookie } from '@/helpers/cookies.js';
import { useHead } from '@unhead/vue'

const route = useRoute();

useHead({ 
    script: [{src: '/js/metrika.js'}],
},{ mode: 'client' });

onMounted(()=> {
    if (Object.keys(route.query).length) {
        for (const key in route.query) {
            setCookie(key, route.query[key])
        }
    }
})
</script>

