import App from './App.vue';
import { createSSRApp } from 'vue';
import { createHead } from '@unhead/vue';
import { createRouter } from './router';
// import { createPinia } from 'pinia';
import lazyload from 'vue3-lazyload'; 

export function createApp() {
    const app = createSSRApp(App)
    
    const head = createHead();
    const router = createRouter();
    // const pinia = createPinia();

    app.use(head)
    app.use(router)
    // app.use(pinia)
    app.use(lazyload, {
        // log: false
    })
    return { app, head, router }
}
